import { default as indexMFGx3ihREYMeta } from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue?macro=true";
export default [
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___au",
    path: indexMFGx3ihREYMeta?.path ?? "/au",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___de",
    path: indexMFGx3ihREYMeta?.path ?? "/de",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___de-en",
    path: indexMFGx3ihREYMeta?.path ?? "/de-en",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___en",
    path: indexMFGx3ihREYMeta?.path ?? "/",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___es",
    path: indexMFGx3ihREYMeta?.path ?? "/es",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___mx",
    path: indexMFGx3ihREYMeta?.path ?? "/mx",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___fr",
    path: indexMFGx3ihREYMeta?.path ?? "/fr",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___it",
    path: indexMFGx3ihREYMeta?.path ?? "/it",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___jp",
    path: indexMFGx3ihREYMeta?.path ?? "/jp",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___ru",
    path: indexMFGx3ihREYMeta?.path ?? "/ru",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___sg",
    path: indexMFGx3ihREYMeta?.path ?? "/sg",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexMFGx3ihREYMeta?.name ?? "home___uk",
    path: indexMFGx3ihREYMeta?.path ?? "/uk",
    meta: { ...(indexMFGx3ihREYMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexMFGx3ihREYMeta?.alias || [],
    redirect: indexMFGx3ihREYMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-homepage-prod/pages/index/index.vue").then(m => m.default || m)
  }
]