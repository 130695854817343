function removePrefix(s: string) {
	if (/^(au|de|de-en|es|fr|it|jp|mx|ru|sg|uk|cn|hk|tw|mo)\//.test(s)) {
		return removePrefix(s.replace(/^(au|de|de-en|es|fr|it|jp|mx|ru|sg|uk|cn|hk|tw|mo)\//, ""));
	}
	return s;
}

const useLocaleLink = () => {
	const websiteStore = useWebsiteStore();
	const runtimeConfig = useRuntimeConfig();
	return (url: string, website?: "") => {
		let str = "";
		let w = "";
		if (url) {
			w = website || websiteStore.website;
			if (runtimeConfig.public.NODE_ENV === "production") {
				if (
					["PROD", "PROD_INDEX", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV) &&
					["cn", "en"].includes(w)
				) {
					w = "";
				}
			}
			let origin = "";
			const urlRegex = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;
			if (urlRegex.test(url)) {
				try {
					const l = new URL(url);
					if (l.origin.includes("community.whgxwl.com") || l.origin.includes("community.fs.com")) {
						w = website || websiteStore.communityWebsite;
					}
					if (l.origin.includes(".fs.com") || l.origin.includes(".whgxwl.com") || l.origin.includes(":2023")) {
						origin = l.origin;
						str = url.replace(/^(https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+)(:\d+)?/, "");
					} else {
						return url;
					}
				} catch (err) {
					// console.log(err);
				}
			} else {
				str = url;
			}
			str = str.replace(/^\/+/, "");

			if (str) {
				str = removePrefix(str);
			}
			if (
				["en", "au", "de", "de-en", "es", "fr", "it", "jp", "mx", "ru", "sg", "uk", "cn", "hk", "tw", "mo"].includes(
					str
				)
			) {
				str = "";
			}

			if (
				["PROD_CN", "PROD", "PROD_INDEX"].includes(runtimeConfig.public.VITE_NUXT_ENV) &&
				!(url.includes("community.whgxwl.com") || url.includes("community.fs.com"))
			) {
				if (["en", "cn"].includes(w)) {
					w = "";
				}
			} else {
				if (w === "en") {
					w = "";
				}
			}
			str = `${origin}${w ? `/${w}/` : `/`}${str}`;
		}
		return str;
	};
};

export default useLocaleLink;
