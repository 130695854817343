interface CookieOptions {
	path?: string;
	domain?: string;
	sameSite?: boolean | "lax" | "none" | "strict";
	httpOnly?: boolean;
	secure?: boolean;
	maxAge?: number;
}

export const useCookies = (cookiename: string, _options: CookieOptions = {}) => {
	const runtimeConfig = useRuntimeConfig();
	const options: CookieOptions = {
		path: "/"
	};
	if (runtimeConfig.public.NODE_ENV === "production") {
		if (["PROD", "PROD_INDEX", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
			options.domain = "fs.com";
		} else if (["RELEASE", "RELEASE_INDEX"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
			options.domain = "whgxwl.com";
		}
	}
	const combineOptions: CookieOptions = Object.assign({}, options, _options);
	const cookie = useCookie(cookiename, combineOptions);
	return cookie;
};
