import { removeQueryParams } from "@/utils/utils";

interface WebsiteState {
	chinese_country_name: string;
	website: string;
	iso_code: string;
	currency: string;
	language: string;
	country_name: string;
	countries_id: number;
	language_id: string;
	warehouse: string;
	tel_prefix: string;
	symbol: string;
	clienIp: string;
	timezone: string;
	isEuUnion: string;
}

export const useWebsiteStore = defineStore("website", {
	state: (): WebsiteState => {
		const website = useCookies("website");
		const iso_code = useCookies("iso_code");
		const currency = useCookies("currency");
		const language = useCookies("language");
		// console.log(website);
		return {
			chinese_country_name: "",
			website: website.value || "",
			iso_code: iso_code.value || "",
			currency: currency.value || "",
			language: language.value || "",
			country_name: "",
			countries_id: 0,
			language_id: "",
			warehouse: "",
			tel_prefix: "",
			symbol: "",
			clienIp: "",
			timezone: "",
			isEuUnion: ""
		};
	},
	getters: {
		isCn: state => state.website === "cn",
		isSg: state => state.website === "sg",
		isJp: state => state.website === "jp",
		isJpEn: state => !["jp"].includes(state.website) && state.countries_id === 107,
		isRussia: state => state.countries_id === 176,
		communityWebsite: state => {
			let str = "";
			if (state.website) {
				if (["en", "uk", "au", "sg", "de-en"].includes(state.website)) {
					str = "";
				} else if (state.website === "mx") {
					str = `es`;
				} else if (["tw", "mo"].includes(state.website)) {
					str = `hk`;
				} else {
					str = `${state.website}`;
				}
			}
			return str;
		}
	},
	actions: {
		async updateWebsite(payload: any) {
			/**
			 * payload 	必传三个参数   iso_code,language,currency
			 *      cllback  接口请求成功后回调函数，选传
			 */

			const headers = useRequestHeaders();
			const url = useRequestURL();
			const runtimeConfig = useRuntimeConfig();
			const { $i18n, $switchLocalePath } = useNuxtApp();
			const { locale, setLocale } = $i18n;
			const localeLink = useLocaleLink();
			const route = useRoute();

			// console.log("000000000000")
			// console.log($i18n)
			// console.log(url)
			// console.log("0000000000000000000")

			const website = useCookies("website");
			const iso_code = useCookies("iso_code");
			const currency = useCookies("currency");
			const language = useCookies("language");
			const fs_websiteinfo = useCookies("fs_websiteinfo");

			const zx_update_websiteinfo = useCookies("zx_update_websiteinfo");

			let obj = {};
			if (payload.iso_code && payload.language && payload.currency) {
				obj = {
					iso_code: payload.iso_code,
					language: payload.language,
					currency: payload.currency
				};
			}

			const oldWebsite = useCookies("oldWebsite");

			oldWebsite.value = this.website;

			const { data, error } = await useRequest.post("/api/website/updateSiteInfo", {
				data: obj
			});

			if (data.value) {
				const responseWebsite = data.value.data;
				// console.log(responseWebsite);
				// console.log(headers);
				// console.log(url);
				nextTick(() => {
					zx_update_websiteinfo.value = responseWebsite;
					fs_websiteinfo.value = responseWebsite;
					website.value = responseWebsite.website;
					iso_code.value = responseWebsite.iso_code;
					currency.value = responseWebsite.currency;
					language.value = responseWebsite.language;

					this.website = responseWebsite.website;
					this.iso_code = responseWebsite.iso_code;
					this.currency = responseWebsite.currency;
					this.language = responseWebsite.language;
					this.country_name = responseWebsite.country_name;
					this.countries_id = responseWebsite.countries_id;
					this.language_id = responseWebsite.language_id;
					this.warehouse = responseWebsite.warehouse;
					this.isEuUnion = responseWebsite.isEuUnion;
					this.tel_prefix = responseWebsite.tel_prefix;
					this.timezone = responseWebsite.timezone;
					this.symbol = responseWebsite.symbol;
					// setLocale(responseWebsite.website);
					let p = "";
					if (
						runtimeConfig.public.VITE_NUXT_ENV &&
						["PROD", "PROD_INDEX", "PROD_CN"].includes(runtimeConfig.public.VITE_NUXT_ENV)
					) {
						if (["PROD", "PROD_INDEX"].includes(runtimeConfig.public.VITE_NUXT_ENV)) {
							if (responseWebsite.website === oldWebsite.value) {
								p = location.href
									.replace(/country=[^&]*&?/g, "")
									.replace(/currency=[^&]*&?/g, "")
									.replace(/languages=[^&]*&?/g, "");
							} else {
								if (["cn", "hk", "tw", "mo"].includes(responseWebsite.website)) {
									p = `https://cn.fs.com`;
									if (responseWebsite.website !== "cn") {
										p += `/${responseWebsite.website}`;
									}
									p += route.fullPath;
									console.log("111111111111");
									console.log(p);
									p = localeLink(p, responseWebsite.website);
									// window.location.href = p;
								} else {
									p = localeLink(route.fullPath, responseWebsite.website);
									// window.location.href = p;
								}
							}
						} else {
							if (responseWebsite.website === oldWebsite.value) {
								p = location.href
									.replace(/country=[^&]*&?/g, "")
									.replace(/currency=[^&]*&?/g, "")
									.replace(/languages=[^&]*&?/g, "");
								// window.location.href = p;
							} else {
								if (["cn", "hk", "tw", "mo"].includes(responseWebsite.website)) {
									p = localeLink(route.fullPath, responseWebsite.website);
									// window.location.href = p;
								} else {
									p = `https://www.fs.com`;
									if (responseWebsite.website !== "en") {
										p += `/${responseWebsite.website}`;
									}
									p += route.fullPath;
									p = localeLink(p, responseWebsite.website);

									// window.location.href = p;
								}
							}
						}
					} else {
						if (responseWebsite.website !== oldWebsite.value) {
							p = localeLink(route.fullPath, responseWebsite.website);
							// console.log("pppppppppppppppp");
							// console.log(route.fullPath);
							// console.log(responseWebsite.website);
							// console.log(localeLink(route.fullPath, responseWebsite.website));
							// window.location.href = p;
						} else {
							p = location.href
								.replace(/country=[^&]*&?/g, "")
								.replace(/currency=[^&]*&?/g, "")
								.replace(/languages=[^&]*&?/g, "");
							// console.log("==============");
							// console.log(website.value);
							// console.log(iso_code.value);
							// console.log(language.value);
							// console.log(currency.value);
							// window.location.href = p;
						}
					}
					console.log("uuuuuuuuuuu");
					console.log(p);
					p = removeQueryParams(p, ["country", "currency", "languages"]);
					setTimeout(() => {
						console.log("new");
						console.log(p);
						window.location.href = p;
					}, 1);
				});
			}
		}
	}
});
